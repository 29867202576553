<template>
  <div>
    <b-card
      title="Katılımcılar"
      no-body
    >
      <b-card-header>
        <b-card-title>
          İzleyiciler
        </b-card-title>
        <div>
          <small>Katılımcı: <b>{{ giveAwayers.length }}</b></small>
        </div>
      </b-card-header>
      <b-alert
        v-if="giveAwayers.length < 1"
        variant="info"
        show
        class="m-1"
      >
        <div class="alert-body text-center">
          <feather-icon icon="InfoIcon" />
          <div>
            Şuanda çekilişe katılmış kullanıcı bulunmuyor.
          </div>
        </div>
      </b-alert>
      <b-table-simple v-else>
        <b-thead>
          <b-tr>
            <b-th>İzleyici</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(line,key) in giveAwayers"
            :key="key"
          >
            <b-td>
              <b-row>
                <b-col
                  md="3"
                >
                  <b-avatar
                    class="mr-1"
                    :text="null"
                  />
                </b-col>
                <b-col>
                  <span class="font-weight-bold">{{ line[0] }}</span>
                </b-col>
              </b-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BCardHeader, BCardTitle, BAvatar, BRow, BCol, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'GiveawayUserCard',
  components: {
    BAlert,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BCardHeader,
    BCardTitle,
    BAvatar,
    BRow,
    BCol,
  },
  data() {
    return {

    }
  },
  computed: {
    users() {
      const users = this.$store.getters['twitch/getGiveAways']
      return users.users
    },
    giveAwayers() {
      const users = this.$store.getters['twitch/getGiveAways']
      return users.giveAwayers
    },
  },
}
</script>
