<template>
  <div>
    <b-card title="Çekiliş">
      <b-form-group
        label="Çekiliş Başlığı"
        label-for="title"
      >
        <b-form-input
          v-model="giveawaysOptions.title"
          placeholder="Çekiliş başlığı"
        />
      </b-form-group>
      <b-form-group
        label="Ödüller"
        label-for="awards"
      >
        <b-form-input
          v-model="giveawaysOptions.awards"
          placeholder="Çekiliş Ödülünü açıklayın.."
        />
      </b-form-group>
      <b-form-group
        label="Anahtar Kelime"
        label-for="keyword"
      >
        <b-form-input
          v-model="giveawaysOptions.keyword"
          placeholder="!join gibi çekilişe katılınacak anahtar kelime"
        />
      </b-form-group>
      <b-form-group
        label-for="time"
        label="Süre"
      >
        <b-input-group :append="'Sn'">
          <b-form-input
            v-model="giveawaysOptions.time"
            placeholder="60"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-for="number"
        label="Kazanan Sayısı"
      >
        <b-input-group :append="'Kişi'">
          <b-form-input
            id="number"
            v-model="giveawaysOptions.number"
            placeholder="1"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-for="limit"
        label="Katılım Limiti"
      >
        <b-input-group :append="'Kişi'">
          <b-form-input
            id="limit"
            v-model="giveawaysOptions.limit"
            placeholder="0"
          />
        </b-input-group>
      </b-form-group>
      <b-button
        v-if="!giveawayStatus"
        variant="primary"
        block
        :disabled="!giveawaysOptions.keyword || !giveawaysOptions.number > 0 || !giveawaysOptions.title || !giveawaysOptions.awards"
        @click="startGiveAway"
      >
        Çekiliş Başlat
      </b-button>
      <b-button
        v-else
        variant="primary"
        block
        @click="stopGiveAway"
      >
        Çekilişi Bitir Ve Sonuçlandır.
      </b-button>
      <div
        v-if="giveawayStatus && giveawaysOptions.time > 0"
        class="mt-2 text-center"
      >
        <small>Çekilişin sonlanmasına <b>{{ giveTimeleft }}</b> saniye..<br>Çekiliş sonlanana kadar sayfadan ayrılmayın..</small>
      </div>
    </b-card>
    <b-modal
      v-model="winnerModal.status"
      title="Kazananlar"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      class="d-flex justify-content-center"
    >
      <div class="mt-1 mb-1">

        <p class="font-medium-2 text-success font-weight-bold"><feather-icon
          icon="CheckCircleIcon"
          size="30"
        /> Kazanan Katılımcılar</p>
        <small>Çekiliş sonuçları sisteme kaydedilmiştir. Çekilişinize <b>Yapılan Çekilişler</b> sayfasından ulaşabilirsiniz.</small>
      </div>
      <b-list-group
        class="d-flex justify-content-center"
        horizontal="md"
      >
        <b-list-group-item
          v-for="(winner,key) in winnerUsers"
          :key="key"
          class="mr-2"
        >
          <b-avatar
            class="mr-1"
            :text="null"
          />
          <span><b>{{ winner }}</b></span>
        </b-list-group-item>
      </b-list-group>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="winnerModal.status = false"
        >
          Tamam
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BFormInput, BInputGroup, BButton, BModal, BListGroup, BListGroupItem, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'GiveawaysCard',
  components: {
    BAvatar,
    BInputGroup,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      giveawaysOptions: {
        title: null,
        awards: null,
        keyword: null,
        time: 60,
        number: 1,
        limit: 0,
        moderator: '0',
        subscribers: '1',
        followers: '1',
      },
      winnerModal: {
        status: false,
      },
      giveawayStatus: false,
      startTime: null,
      endTime: null,
      giveIntervalID: null,
      giveTimeleft: 0,
      winnerUsers: [],
    }
  },
  computed: {
    giveAways() {
      return this.$store.getters['twitch/getGiveAways']
    },
    twitchConfig() {
      return this.$store.getters['twitchConfigApi/dataItem']
    },
  },
  created() {
  },
  methods: {
    startGiveAway() {
      if (!this.giveawayStatus) {
        this.giveawayStatus = true
        this.giveAways.giveAwayers = []
        if (this.giveawaysOptions.time > 0) {
          this.giveTimeleft = this.giveawaysOptions.time
          console.log(`cekilis ${this.giveawaysOptions.time} saniye sonrasi sonlanacak`)
          this.timeleft()
        }
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Çekiliş Başladı.',
        })
      }
      const giveAwayFunction = () => {
        if (!this.giveawayStatus) {
          clearTimeout(this.giveIntervalID) // giveAwayFunction durdurulduğunda setTimeout çağrısını iptal et
        } else {
          if (this.giveawaysOptions.limit > 0 && this.giveAways && this.giveawayStatus && this.giveAways.giveAwayers.length >= this.giveawaysOptions.limit) {
            this.stopGiveAway()
            this.giveAways.giveAwayers.slice(0, this.giveawaysOptions.limit)
            return
          }
          fetch(`https://valeri-node-js.vercel.app/get-chat-messages?keyword=${this.giveawaysOptions.keyword}`)
            .then(response => {
              if (!response.ok) {
                throw new Error('Karsi sunucudan beklendiği gibi cevap alinamadi.')
              }
              return response.json() // Cevabı JSON olarak dönüştür
            })
            .then(data => {
              // Verileri kullanma işlemlerini burada gerçekleştirin
              if (data.giveAwayers.length > 0) {
                this.$store.commit('twitch/SET_GIVE_AWAYS', data.data)
                // Set veri yapısı oluştur
                const existingGiveAwayers = new Set(this.giveAways.giveAwayers.flat())
                // Yeni verileri temizle ve mevcut verilerle karşılaştırarak eklemek için filtreleme işlemi
                const newGiveAwayers = data.giveAwayers.filter(giveAwayer => giveAwayer && !existingGiveAwayers.has(giveAwayer))
                // Yeni verileri mevcut verilere ekle
                if (newGiveAwayers.length > 0) {
                  if (this.giveawayStatus) {
                    this.giveAways.giveAwayers.push(newGiveAwayers)
                  }
                }
              }
              giveAwayFunction()
            })
            .catch(error => {
              console.error('Karsi sunucuya istek gonderilirken hata meydana geldi:', error)
            })
        }
      }
      fetch(`https://valeri-node-js.vercel.app/start-giveaway?username=${this.twitchConfig.channel_name}&token=${this.twitchConfig.user_access_token}&keyword=${this.giveawaysOptions.keyword}`)
        .then(response => {
          if (response.ok) {
            giveAwayFunction()
          } else {
            this.showToast({
              variant: 'warning', title: 'İşlem Başarısız!', icon: 'CheckIcon', text: 'Çekiliş için beklenen cevap alınamadı.',
            })
          }
        })
    },
    stopGiveAway() {
      if (!this.giveawayStatus) {
        return
      }
      this.giveawayStatus = false
      this.startTime = null
      this.endTime = null
      this.winnerUsers = []
      clearTimeout(this.giveIntervalID)
      // kazananları belirleme vs.

      if (this.giveAways.giveAwayers && this.giveAways.giveAwayers.length > 0) {
        const giveAwayersLength = this.giveAways.giveAwayers.length

        // Limit değeri kadar rastgele kazanan seç
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j <= this.giveawaysOptions.number - 1; j++) {
          const randomIndex = Math.floor(Math.random() * giveAwayersLength)
          const selectedWinner = this.giveAways.giveAwayers[randomIndex][0]
          if (!this.winnerUsers.includes(selectedWinner)) {
            this.winnerUsers.push(selectedWinner)
          }
        }
        this.winnerModal.status = true
        this.$store.dispatch('giveaways/saveData', {
          participants: this.giveAways.giveAwayers,
          winners: this.winnerUsers,
          title: this.giveawaysOptions.title,
          awards: this.giveawaysOptions.awards,
        })
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Çekiliş Sonlandırıldı.',
        })
      } else {
        this.showToast({
          variant: 'warning', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: 'Çekiliş Sonlandırıldı. Fakat Katılan olmadığı için kazanan açıklanmadı',
        })
      }
      const winnersJson = JSON.stringify(this.winnerUsers)
      const winnersEncoded = encodeURIComponent(winnersJson)
      fetch(`https://valeri-node-js.vercel.app/finish-giveaway?username=${encodeURIComponent(this.twitchConfig.channel_name)}&winners=${winnersEncoded}`)
    },
    timeleft() {
      if (this.giveawayStatus) {
        this.giveTimeleft -= 1
        if (this.giveTimeleft < 1) {
          this.stopGiveAway()
        } else {
          setTimeout(() => {
            this.timeleft()
          }, 1000)
        }
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
