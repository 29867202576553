<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <giveaway-user-card />
      </b-col>
      <b-col>
        <giveaways-card />
      </b-col>
      <b-col>
        <giveaway-chat-card />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import GiveawayUserCard from '@/views/Admin/Twitch/Giveaways/UsersCard.vue'
import GiveawaysCard from '@/views/Admin/Twitch/Giveaways/GiveawaysCard.vue'
import GiveawayChatCard from '@/views/Admin/Twitch/Giveaways/ChatCard.vue'

export default {
  name: 'Giveaways',
  components: {
    GiveawayChatCard,
    GiveawaysCard,
    GiveawayUserCard,
    BRow,
    BCol,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {
    // saveStatus(val) {
    //   if (val.status === true) {
    //     const config = {
    //       icon: 'success',
    //       title: 'İşlem Başarılı!',
    //       text: val.message,
    //       html: null,
    //       buttonText: 'Kapat',
    //     }
    //     this.sweetAlert(config)
    //   } else if (val.status === false) {
    //     const config = {
    //       icon: 'error',
    //       title: 'İşlem Hatası!',
    //       text: val.message,
    //       html: null,
    //       buttonText: 'Kapat',
    //     }
    //     this.sweetAlert(config)
    //   }
    //   this.submitStatus = false
    // },
  },
  created() {

  },
  methods: {

  },
}
</script>
