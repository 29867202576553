<template>
  <div>
    <b-card title="Yayın Chati">
      <iframe
        id="twitch-chat-embed"
        :src="'https://www.twitch.tv/embed/'+twitchConfig.channel_name+'/chat?parent='+$store.state.app.twitch_embed_base_url"
        height="600"
        width="100%"
        style="border: none !important;"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'GiveawayChatCard',
  components: {
    BCard,
  },
  data() {
    return {

    }
  },
  computed: {
    twitchConfig() {
      return this.$store.getters['twitchConfigApi/dataItem']
    },
  },
  created() {
    this.getTwitchConfig()
  },
  methods: {
    getTwitchConfig() {
      this.$store.dispatch('twitchConfigApi/getDataItem')
    },
  },
}
</script>
